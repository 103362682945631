import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Bizim haqqımızdaCasserosholes Co-Tədqiqat
			</title>
			<meta name={"description"} content={"Suredtrive Akademiyasındakı missiyamız sürücülük təlimindən kənara çıxır."} />
			<meta property={"og:title"} content={"Bizim haqqımızdaCasserosholes Co-Tədqiqatı"} />
			<meta property={"og:description"} content={"Suredtrive Akademiyasındakı missiyamız sürücülük təlimindən kənara çıxır."} />
			<meta property={"og:image"} content={"https://nikimans.live/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nikimans.live/media/34236436.svg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nikimans.live/media/34236436.svg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nikimans.live/media/34236436.svg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nikimans.live/media/34236436.svg"} />
			<link rel={"apple-touch-startup-image"} href={"https://nikimans.live/media/34236436.svg"} />
			<meta name={"msapplication-TileImage"} content={"https://nikimans.live/media/34236436.svg"} />
		</Helmet>
		<Components.Header />
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-darkL2"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Bizim haqqımızda
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					Suredtrive Akademiyasındakı missiyamız sürücülük təlimindən kənara çıxır.Güvən aşılamaq, təhlükəsiz sürücülük bacarıqlarını aşılamaq və şagirdlərimizə həm bacarıq baxımından, həm də düşüncə baxımından yola hazırlıq vermək üçün buradayıq.Akademiyamız, təlimin təsirli olduğu qədər xoş olduğu ənənələr və yeniliklərin birləşməsidir.
				</Text>
			</Box>
			<Box
				display="flex"
				width="60%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://nikimans.live/media/2.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					max-height="600px"
					width="100%"
					object-position="0% 70%"
					sm-max-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="30%"
						md-width="100%"
					>
						Təhlükəsiz, inamlı sürücülük üçün istiqamət
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="70%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						İnanıqlı bir sürücü təhlükəsiz bir sürücü olduğuna inanırıq.Kurslarımız bu fəlsəfə üzərində qurulur, Suredtrive Akademiyasının hər bir məzunu yalnız sürücülük mexanikasına sahib deyil, həm də yolda və etiketdə hansı təhlükəsizliyin olduğunu başa düşür.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="30%"
						md-width="100%"
					>
						Tariximiz
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="70%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						SureDrive Academy-də tək bir qurucusu yoxdur - bu, açarı alovlanma kilidlərimizdən birində çevirən hər bir tələbənin yazdığı bir hekayədir.Hər bir uğurla səyahət edən hər bir test, hər bir qorxu və tələbələrimizin əldə etdiyi bütün mərhələləri zəngin, inkişaf edən povestimizə töhfə verir.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="30%"
						md-width="100%"
					>
						Niyə Suredtrive Akademiyasını seçməyə dəyər?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="70%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						- Öyrənməyə fərdi yanaşma: Tədrisə yanaşmamız, heç kimin diqqətsiz qalmayacağına zəmanət verən hər bir tələbənin tempi və öyrənmə tərzini nəzərə alır.
						<br />
						<br />
						- Müasir simulyatorlar: Yolda məşqlə yanaşı, hərtərəfli təcrübə qazanmaq üçün ən müasir simulyatorlarda dərslər təklif edirik.
						<br />
						<br />
						- Müxtəlif kurslar: başlanğıcdan qoruyucu sürücülük və xüsusi təlim də daxil olmaqla inkişaf etmiş bir səviyyəyə qədər, bütün səviyyələri və ehtiyaclarını təmin edirik.
						<br />
						<br />
						- Maraqlı vəziyyət: Təlim otaqlarımız qonaqpərvər olmağa və təlim üçün optimal atmosferin yaradılmasına töhfə vermək üçün belə bir şəkildə hazırlanmışdır.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="30%"
						md-width="100%"
					>
						Öhdəliklərimiz
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="70%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Suredtrive Akademiyada, yollarda inamlı və diqqətlə səyahət etməyə hazır olan məsuliyyətli, təcrübəli sürücülər yaratmağa çalışırıq.			</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});